import { useApiMutation, useApiQuery } from "@api/fetch"
import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  useUser
} from "@clerk/clerk-react"
import type {
  SelectTransaction,
  SliderInputProps,
  ToolbarButtonProps,
  ToolbarProps
} from "@repo/types"
import { queryClient } from "@root/main"
import { useCredits } from "@state/useCredits"
import { useDrawingStore } from "@state/useDrawStore"
import { Link } from "@tanstack/react-router"

import cx from "classnames"

import styles from "./Toolbar.module.css"

import { Slider } from "@components/Slider"
import iconBolt from "@icons/bolt.svg?raw"
import iconBrush from "@icons/brush.svg?raw"
import iconGallery from "@icons/gallery.svg?raw"
import iconSend from "@icons/send.svg?raw"
import iconUndo from "@icons/undo.svg?raw"

export function Toolbar({
  busy,
  onUndo,
  onSend,
  onMode,
  layoutClassName = undefined
}: ToolbarProps) {
  const brushThickness = useDrawingStore((x) => x.brushThickness)
  const { setBrushThickness } = useDrawingStore()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Actions {...{ busy, onSend, onUndo, onMode }} />
      {/* <SliderInput
        disabled={busy}
        value={brushThickness}
        layoutClassName={styles.sliderLayout}
        onSlideChange={setBrushThickness}
      /> */}

      <Slider
        value={brushThickness}
        onValueChange={setBrushThickness}
        layoutClassName={styles.sliderLayout}
      />

      <Profile />

      <div className={styles.send}>
        <ToolbarButton disabled={busy} onClick={onSend}>
          {busy ? <Loading /> : <SendButton />}
        </ToolbarButton>
      </div>
    </div>
  )
}

function Actions({
  busy,
  onUndo,
  onMode
}: Pick<ToolbarProps, "busy" | "onUndo" | "onMode">) {
  const mode = useDrawingStore((x) => x.mode)

  return (
    <div className={styles.componentActions} data-active={mode}>
      <ToolbarButton onClick={onUndo} disabled={busy}>
        <UndoIcon />
      </ToolbarButton>

      <ToolbarButton
        isActive={mode === "feather"}
        onClick={() => onMode("feather")}
        disabled={busy}
      >
        <BrushIcon />
      </ToolbarButton>

      <ToolbarButton
        isActive={mode === "brush"}
        onClick={() => onMode("brush")}
        disabled={busy}
      >
        <BrushIcon />
      </ToolbarButton>

      <ToolbarButton
        isActive={mode === "lasso"}
        onClick={() => onMode("lasso")}
        disabled={busy}
      >
        <BoltIcon />
      </ToolbarButton>
    </div>
  )
}

function Profile() {
  const balance = useCredits((x) => x.balance)

  return (
    <div className={styles.componentProfile}>
      <code className={styles.balance}>{balance}</code>

      <Link to='/gallery' className={styles.link}>
        <GalleryIcon />
      </Link>

      <div className={styles.profile}>
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          {/**
            NOTE: Hier kan settings in.
            @see https://clerk.com/docs/customization/user-profile 
          */}
          <UserButton>
            <UserButton.UserProfilePage
              label='Admin Settings'
              url='/admin'
              labelIcon={<div />}
            >
              <AdminSettings />
            </UserButton.UserProfilePage>

            <UserButton.UserProfilePage
              label='Transactions'
              url='/transactions'
              labelIcon={<div />}
            >
              <TransactionsView />
            </UserButton.UserProfilePage>
          </UserButton>
        </SignedIn>
      </div>
    </div>
  )
}

function AdminSettings() {
  const { user } = useUser()

  const { mutate: increaseMutation } = useApiMutation("/credits/increase", {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["credits", user?.id] })
  })

  const { mutate: decreaseMutation } = useApiMutation("/credits/decrease", {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["credits", user?.id] })
  })

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <button type='button' onClick={() => increaseMutation({})}>
        increase with 1 credit
      </button>

      <button type='button' onClick={() => decreaseMutation({})}>
        decrease with 1 credit
      </button>
    </div>
  )
}

function TransactionsView() {
  const { data } = useApiQuery<{ transactions: Array<SelectTransaction> }>(
    ["transactions"],
    "/transactions/list"
  )

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <table>
        <thead>
          <th>id</th>
          <th>type</th>
          <th>amount</th>
          <th>createdAt</th>
        </thead>

        <tbody>
          {data?.transactions?.map((x) => (
            <tr key={x.id}>
              <td>{x.id}</td>
              <td>{x.type}</td>
              <td>{x.amount}</td>
              <td>{new Date(x.createdAt).toLocaleDateString("nl")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function ToolbarButton({
  children,
  onClick,
  isActive = false,
  disabled = false,
  layoutClassName = undefined
}: ToolbarButtonProps) {
  return (
    <button
      type='button'
      className={cx(
        styles.componentButton,
        isActive && styles.isActive,
        layoutClassName
      )}
      {...{ disabled, onClick }}
    >
      {children}
    </button>
  )
}

function BoltIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconBolt }}
    />
  )
}

function UndoIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconUndo }}
    />
  )
}

function BrushIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconBrush }}
    />
  )
}

function SendButton() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconSend }}
    />
  )
}

function GalleryIcon() {
  return (
    <span
      className={styles.icon}
      dangerouslySetInnerHTML={{ __html: iconGallery }}
    />
  )
}

function Loading() {
  return <span className={styles.componentLoader} />
}

function SliderInput({
  value,
  disabled,
  onSlideChange,
  layoutClassName
}: SliderInputProps) {
  return (
    <div className={cx(styles.componentSliderInput, layoutClassName)}>
      <input
        type='range'
        min={1}
        max={30}
        className={cx(styles.slider, layoutClassName)}
        onChange={(e) => onSlideChange(Number(e.currentTarget.value))}
        style={{ "--value": (value - 1) / (30 - 1) } as React.CSSProperties}
        {...{ disabled, value }}
      />
    </div>
  )
}
