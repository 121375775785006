import type { ProjectsDetailResponse, ProjectsListResponse } from "@repo/types"
import { queryOptions } from "@tanstack/react-query"
import { createApiClient } from "../fetch"

export default {
  list: (getToken: () => Promise<string | null>) =>
    queryOptions({
      queryKey: ["projects"],
      queryFn: async () => {
        const api = createApiClient(getToken)
        return api.get<ProjectsListResponse>("/projects/list")
      }
    }),

  detail: (id: string, getToken: () => Promise<string | null>) =>
    queryOptions({
      queryKey: ["project", id],
      queryFn: async () => {
        const api = createApiClient(getToken)
        return api.get<ProjectsDetailResponse>(`/projects/${id}`)
      }
    })
}
