import { Gallery } from "@pages/Gallery"
import { queryClient } from "@root/main"
import { createFileRoute } from "@tanstack/react-router"

import projectQueries from "@api/queries/projects"

export const Route = createFileRoute("/_auth/gallery")({
  loader: async ({ context }) => {
    const query = projectQueries.list(context.auth.getToken)
    return queryClient.ensureQueryData(query)
  },
  component: Gallery
})
