/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthGalleryImport } from './routes/_auth.gallery'
import { Route as AuthEditorIdImport } from './routes/_auth.editor.$id'

// Create Virtual Routes

const AuthNewLazyImport = createFileRoute('/_auth/new')()
const AuthAboutLazyImport = createFileRoute('/_auth/about')()

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthNewLazyRoute = AuthNewLazyImport.update({
  id: '/new',
  path: '/new',
  getParentRoute: () => AuthRoute,
} as any).lazy(() => import('./routes/_auth.new.lazy').then((d) => d.Route))

const AuthAboutLazyRoute = AuthAboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => AuthRoute,
} as any).lazy(() => import('./routes/_auth.about.lazy').then((d) => d.Route))

const AuthGalleryRoute = AuthGalleryImport.update({
  id: '/gallery',
  path: '/gallery',
  getParentRoute: () => AuthRoute,
} as any)

const AuthEditorIdRoute = AuthEditorIdImport.update({
  id: '/editor/$id',
  path: '/editor/$id',
  getParentRoute: () => AuthRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_auth/gallery': {
      id: '/_auth/gallery'
      path: '/gallery'
      fullPath: '/gallery'
      preLoaderRoute: typeof AuthGalleryImport
      parentRoute: typeof AuthImport
    }
    '/_auth/about': {
      id: '/_auth/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AuthAboutLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/new': {
      id: '/_auth/new'
      path: '/new'
      fullPath: '/new'
      preLoaderRoute: typeof AuthNewLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/editor/$id': {
      id: '/_auth/editor/$id'
      path: '/editor/$id'
      fullPath: '/editor/$id'
      preLoaderRoute: typeof AuthEditorIdImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthGalleryRoute: typeof AuthGalleryRoute
  AuthAboutLazyRoute: typeof AuthAboutLazyRoute
  AuthNewLazyRoute: typeof AuthNewLazyRoute
  AuthEditorIdRoute: typeof AuthEditorIdRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthGalleryRoute: AuthGalleryRoute,
  AuthAboutLazyRoute: AuthAboutLazyRoute,
  AuthNewLazyRoute: AuthNewLazyRoute,
  AuthEditorIdRoute: AuthEditorIdRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/gallery': typeof AuthGalleryRoute
  '/about': typeof AuthAboutLazyRoute
  '/new': typeof AuthNewLazyRoute
  '/editor/$id': typeof AuthEditorIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/gallery': typeof AuthGalleryRoute
  '/about': typeof AuthAboutLazyRoute
  '/new': typeof AuthNewLazyRoute
  '/editor/$id': typeof AuthEditorIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_auth/gallery': typeof AuthGalleryRoute
  '/_auth/about': typeof AuthAboutLazyRoute
  '/_auth/new': typeof AuthNewLazyRoute
  '/_auth/editor/$id': typeof AuthEditorIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '' | '/gallery' | '/about' | '/new' | '/editor/$id'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '' | '/gallery' | '/about' | '/new' | '/editor/$id'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/_auth/gallery'
    | '/_auth/about'
    | '/_auth/new'
    | '/_auth/editor/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/gallery",
        "/_auth/about",
        "/_auth/new",
        "/_auth/editor/$id"
      ]
    },
    "/_auth/gallery": {
      "filePath": "_auth.gallery.tsx",
      "parent": "/_auth"
    },
    "/_auth/about": {
      "filePath": "_auth.about.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/new": {
      "filePath": "_auth.new.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/editor/$id": {
      "filePath": "_auth.editor.$id.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
