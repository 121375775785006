import type {
  LinePointCoordinate,
  MouseDownHandlerHook,
  MouseDownHandlerHookReturn
} from "@repo/types"
import { useEffect, useRef } from "react"

import useEventCallback from "@machinery/useEvent"

export function useDrawingHandlers({
  svgRef,
  addPoint,
  startLine,
  completeLine
}: MouseDownHandlerHook): MouseDownHandlerHookReturn {
  const isDrawingRef = useRef(false)

  const onMouseMove = useEventCallback(handleMouseMove)
  const onMouseUp = useEventCallback(handleMouseUp)

  useEffect(() => {
    const svg = svgRef.current
    if (!svg) return

    svg.addEventListener("mousemove", onMouseMove)
    window.addEventListener("mouseup", onMouseUp)

    return () => {
      svg.removeEventListener("mousemove", onMouseMove)
      window.removeEventListener("mouseup", onMouseUp)
    }
  }, [svgRef.current, onMouseMove, onMouseUp])

  function handleMouseDown(event: MouseEvent) {
    isDrawingRef.current = true
    const point = getSvgPoint(svgRef.current, event)
    startLine(point)
  }

  function handleMouseMove(event: MouseEvent) {
    if (!isDrawingRef.current) return
    addPoint(getSvgPoint(svgRef.current, event))
  }

  function handleMouseUp() {
    isDrawingRef.current = false
    completeLine()
  }

  return { handleMouseDown }
}

function getSvgPoint(
  svg: SVGSVGElement,
  event: MouseEvent
): LinePointCoordinate {
  const point = svg.createSVGPoint()
  point.x = event.clientX
  point.y = event.clientY
  const svgPoint = point.matrixTransform(svg.getScreenCTM()?.inverse())

  return [svgPoint.x, svgPoint.y]
}
