import * as localizations from "@clerk/localizations"
import ReactDOM from "react-dom/client"

import { ClerkProvider, useAuth } from "@clerk/clerk-react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import { StrictMode } from "react"
import { useTranslation } from "react-i18next"
import { routeTree } from "./routeTree.gen"

import "./i18n"
import "./index.css"

import "./cssGlobal/type.css"
import "./cssGlobal/color.css"
import "./cssGlobal/motion.css"

export const queryClient = new QueryClient()

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    auth: undefined!
  },
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0
})

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const rootElement = document.getElementById("root")!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <AppContext />
    </StrictMode>
  )
}

function AppContext() {
  const { i18n } = useTranslation()

  return (
    <QueryClientProvider client={queryClient}>
      <ClerkProvider
        localization={localizations[i18n.languages[0]]}
        publishableKey={PUBLISHABLE_KEY}
        afterSignOutUrl='/'
      >
        <AuthWrappedProvider />
      </ClerkProvider>
    </QueryClientProvider>
  )
}

function AuthWrappedProvider() {
  const auth = useAuth()
  return <RouterProvider router={router} context={{ auth }} />
}
