import { Outlet, createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/_auth")({
  component: Outlet,
  beforeLoad: async ({ context }) => {
    const token = await context.auth.getToken()
    if (!token) throw redirect({ to: "/" })
  },
  errorComponent: ({ error }) => {
    if (error.message === "Not authenticated") {
      return <div className='flex items-center justify-center p-12'>uh oh</div>
    }

    throw error
  }
})
