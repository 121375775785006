import { useApiQuery } from "@api/fetch"
import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  useAuth
} from "@clerk/clerk-react"
import type {
  GlobalComponent,
  ImageDetailResponse,
  ImageListResponse,
  R2ImageProps,
  SelectGeneration,
  SelectProject
} from "@repo/types"
import { useSuspenseQuery } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"

import projectQueries from "@api/queries/projects"
import cx from "classnames"

import styles from "./Gallery.module.css"

import logo from "@assets/logo-icon.svg"
import iconBrush from "@icons/brush.svg?raw"

export function Gallery() {
  const { t } = useTranslation("gallery")

  const { getToken } = useAuth()
  const { data: projectData, isLoading: isLoadingProjects } = useSuspenseQuery(
    projectQueries.list(getToken)
  )

  const { data: list, isLoading: isLoadingList } =
    useApiQuery<ImageListResponse>(["list"], "/images/list")

  return (
    <div className={styles.page}>
      <header className={styles.tempHeader}>
        <span className={styles.tempHeaderTitle}>
          <Logo />
          <p>{t("title")}</p>
        </span>

        <div className={styles.tempHeaderActions}>
          <Link to='/new' className={styles.link}>
            <EditorIcon />
          </Link>

          <SignedOut>
            <SignInButton />
          </SignedOut>
          <SignedIn>
            <UserButton />
          </SignedIn>
        </div>
      </header>

      <hr />

      {!isLoadingProjects && <ProjectList {...{ projectData }} />}

      <hr />

      {!isLoadingList && <ImageList {...{ list }} />}
    </div>
  )
}

function ProjectList({
  projectData
}: { projectData: { projects: Array<SelectProject> } | undefined }) {
  const { t } = useTranslation("gallery")

  return (
    <div className={styles.galleryContainer}>
      <b>{t("projects")}</b>
      <ol className={styles.list}>
        {projectData?.projects?.map((x) => (
          <li key={x.id} className={cx(styles.listItem, styles.project)}>
            <span className={styles.label}>PROJECT</span>
            <span className={styles.title}>{x.name}</span>
          </li>
        ))}
      </ol>
    </div>
  )
}

function ImageList({
  list
}: { list: { data: Array<SelectGeneration> } | undefined }) {
  const { t } = useTranslation("gallery")

  return (
    <div className={styles.galleryContainer}>
      <b>{t("previous")}</b>
      <ol className={styles.list}>
        {list?.data?.map((x, i) => (
          <li key={x.id} className={styles.listItem}>
            <R2Image index={i} id={x.objectId} {...{ list }} />
          </li>
        ))}
      </ol>
    </div>
  )
}

function Logo({ layoutClassName }: GlobalComponent) {
  return (
    <Link to='/new' className={styles.componentLogo}>
      <img className={cx(styles.logo, layoutClassName)} alt='' src={logo} />
    </Link>
  )
}

function EditorIcon() {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <span dangerouslySetInnerHTML={{ __html: iconBrush }} />
}

function R2Image({ id }: R2ImageProps) {
  const { data } = useApiQuery<ImageDetailResponse>(
    ["image", id],
    `/images/${id}`
  )

  return data && <img alt='' className={styles.componentR2Image} src={data} />
}
