import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  useUser
} from "@clerk/clerk-react"
import type { GlobalComponent } from "@repo/types"
import { Link } from "@tanstack/react-router"

import cx from "classnames"

import styles from "./App.module.css"

import logo from "@assets/logo-icon.svg"

export default function App() {
  return <Workspace layoutClassName={styles.workspaceLayout} />
}

function Workspace({ layoutClassName = undefined }) {
  const { isSignedIn } = useUser()

  return (
    <div className={cx(styles.componentWorkspace, layoutClassName)}>
      <div className={styles.container}>
        <Logo layoutClassName={styles.logoLayout} />
        {isSignedIn && (
          <Link to='/new'>
            <span>Navigate to editor</span>
          </Link>
        )}
        <Profile layoutClassName={styles.profileLayout} />
      </div>
    </div>
  )
}

function Logo({ layoutClassName }: GlobalComponent) {
  return (
    <Link to='/' className={cx(styles.componentLogo, layoutClassName)}>
      <img className={styles.logo} alt='' src={logo} />
    </Link>
  )
}

function Profile({ layoutClassName }: GlobalComponent) {
  return (
    <div className={cx(styles.componentProfile, layoutClassName)}>
      <SignedOut>
        <SignInButton />
      </SignedOut>
      <SignedIn>
        <UserButton />
      </SignedIn>
    </div>
  )
}
