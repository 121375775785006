import { Workspace } from "@components/Workspace"
import { queryClient } from "@root/main"
import { useDrawingStore } from "@state/useDrawStore"
import { useFile } from "@state/useFile"
import { createFileRoute } from "@tanstack/react-router"

import projectQueries from "@api/queries/projects"
import { useLabel } from "@state/useLabel"

export const Route = createFileRoute("/_auth/editor/$id")({
  loader: async ({ params, context }) => {
    const query = projectQueries.detail(params.id, context.auth.getToken)
    return queryClient.ensureQueryData(query)
  },
  component: Workspace,
  beforeLoad: () => {
    useFile.getState().clear()
    useLabel.getState().clear()
    useDrawingStore.getState().removeLines()
  }
})
