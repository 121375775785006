import type { Base64String, ImageUploadProps } from "@repo/types"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

import cx from "classnames"

import styles from "./Uploader.module.css"

export function Uploader({
  mutation,
  file,
  onFile,
  layoutClassName = undefined
}: ImageUploadProps) {
  const { t } = useTranslation("toolbar")
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <fieldset
      className={cx(styles.fieldset, styles.fileinputfield, layoutClassName)}
    >
      <input
        onChange={(x) =>
          handleChange((x.target.files as FileList)?.[0] || null)
        }
        ref={inputRef}
        className={styles.filebutton}
        disabled={mutation.isPending}
        type='file'
      />

      {file?.name && file?.base64 ? (
        <div className={styles.fauxfilename}>{file.name}</div>
      ) : (
        <button
          type='button'
          onClick={() => inputRef.current?.click()}
          className={styles.fauxfilebutton}
        >
          {t("select-file")}
        </button>
      )}
    </fieldset>
  )

  function handleChange(file: File) {
    if (file)
      toBase64(file).then((x) =>
        onFile({
          name: file.name,
          base64: x
        })
      )
  }
}

async function toBase64(file: File): Promise<Base64String> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = (e) => reject(e)
  })
}
