import { useAuth } from "@clerk/clerk-react"
import type { RequestMethod } from "@repo/types"
import {
  type QueryKey,
  type UseMutationOptions,
  type UseQueryOptions,
  keepPreviousData,
  useMutation,
  useQuery
} from "@tanstack/react-query"

const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:3000/api"

export class APIError extends Error {
  constructor(
    public status: number,
    public data: unknown,
    message?: string
  ) {
    super(message || "API Error")
  }
}

interface RequestConfig extends RequestInit {
  data?: unknown
}

export function useApiClient() {
  const { getToken } = useAuth()
  return createApiClient(getToken)
}

export function createApiClient(getToken: () => Promise<string | null>) {
  async function fetchWithAuth(
    endpoint: string,
    method: RequestMethod = "GET",
    config: RequestConfig = {}
  ) {
    const token = await getToken()
    const url = `${API_BASE_URL}${endpoint}`

    const headers = new Headers({
      "Content-Type": "application/json",
      ...config.headers
    })

    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }

    const fetchConfig: RequestInit = {
      ...config,
      method,
      headers,
      body: config.data ? JSON.stringify(config.data) : undefined
    }

    const response = await fetch(url, fetchConfig)

    if (!response.ok) {
      const errorData = await response.json().catch(() => null)
      throw new APIError(response.status, errorData)
    }

    return response.json()
  }

  return {
    get: <T>(endpoint: string, config?: RequestConfig) =>
      fetchWithAuth(endpoint, "GET", config) as Promise<T>,

    post: <T>(endpoint: string, data?: unknown, config?: RequestConfig) =>
      fetchWithAuth(endpoint, "POST", { ...config, data }) as Promise<T>,

    delete: <T>(endpoint: string, data?: unknown, config?: RequestConfig) =>
      fetchWithAuth(endpoint, "DELETE", { ...config, data }) as Promise<T>
  }
}

export function useApiQuery<TData = unknown, TError = APIError>(
  queryKey: QueryKey,
  endpoint: string,
  options?: Omit<UseQueryOptions<TData, TError, TData>, "queryKey" | "queryFn">
) {
  const api = useApiClient()

  return useQuery<TData, TError>({
    queryKey,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    queryFn: () => api.get<TData>(endpoint),
    ...options
  })
}

export function useApiMutation<
  TData = unknown,
  TVariables = unknown,
  TError = APIError
>(
  endpoint: string,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, "mutationFn">
) {
  const api = useApiClient()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) => api.post<TData>(endpoint, variables),
    ...options
  })
}

export function useApiDeleteMutation<
  TData = unknown,
  TVariables = unknown,
  TError = APIError
>(
  endpoint: string,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, "mutationFn">
) {
  const api = useApiClient()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) => api.delete<TData>(endpoint, variables),
    ...options
  })
}
