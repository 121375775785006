import { Layout } from "@components/Layout"
import type { QueryClient } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'

interface Context {
  queryClient: QueryClient
  auth: { getToken: () => Promise<string | null> }
}

export const Route = createRootRouteWithContext<Context>()({
  component: Root
})

function Root() {
  return (
    <Layout>
      <Outlet />
      {/* <TanStackRouterDevtools /> */}
    </Layout>
  )
}
