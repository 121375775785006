import { useState, useEffect, type MutableRefObject } from "react"

const useElementSize = (ref: MutableRefObject<HTMLElement | null>) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0
  })

  useEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        setSize({ width, height })
      }
    })

    observer.observe(ref.current)

    const { width, height } = ref.current.getBoundingClientRect()
    setSize({ width, height })

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return size
}

export default useElementSize
